export default {
  methods: {
    async searchProjects (searchTerm) {
      if (searchTerm) {
        await this.$api.execute('get', `moduledata/${this.PROJECT}/search?searchterm=${searchTerm}`).then(response => {
          response.data.forEach(item => {
            item.data.name = `${item.data.number || ''} ${item.data.name || ''}`
          })
          this.listOfProjects = response.data
        })
      }
    },
    getProjectById (modelObj) {
      if (modelObj.modulerelations && modelObj.modulerelations.length > 0) {
        const projectFound = modelObj.modulerelations.find(x => x.module === this.PROJECT)
        if (projectFound && projectFound._id) {
          this.$api.execute('get', `moduledata/${this.PROJECT}/${projectFound._id}`).then(response => {
            response.data.data.name = `${response.data.data.number || ''} ${response.data.data.name || ''}`
            this.listOfProjects.push(response.data)
          })
        }
      }
    }
  }
}
