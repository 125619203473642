<template>
  <v-card flat>
    <v-toolbar :color="systemDetails.themecolor">
      <v-btn icon dark small @click="$router.go(-1)">
        <v-icon :color="systemDetails.textcolor">mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title :style="`color: ${systemDetails.textcolor}`" v-if="modelObj.number">
        #{{ modelObj.number }}
        <span class="pl-2 body-1 text-truncate">{{ modelObj.subject }}</span>
      </v-toolbar-title>
      <!-- <v-spacer></v-spacer> -->
      <!-- <v-chip class="mx-1" color="cyan" small label text-color="white" v-if="mailsMerged">
        {{ singularPluralData(mailsMerged) }}
      </v-chip> -->
    </v-toolbar>
    <v-tabs v-model="tab" :background-color="!$vuetify.theme.dark ? 'grey lighten-4' : ''" show-arrows touchless>
      <v-tab v-for="(item) in tabs" :key="item.id">
        <v-icon>{{ item.icon }}</v-icon>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" touchless>
      <v-tab-item  v-for="(item, keyIndex) in tabs" :key="keyIndex" touchless>
        <template v-if="item.id === 0">
          <form-data :references.sync="formReferences" :model="modelObj" ref="ticketViewEditReference">
            <template slot="extraDetails">
              <v-row no-gutters>
                <v-col v-for="(item, index) in modulesRelated" cols="12" sm="6" md="4" xl="2" class="pl-4 pr-4" :key="index">
                  <v-autocomplete  outlined dense hide-details :label="$t(item.label)" v-model="item.selectedValue" v-if="item.name === PROJECT" @keyup="searchProjects($event.target.value)"
                    :loading="projectLoader" :items="listOfProjects" item-text="data.name" item-value="_id" class="mb-4" clearable>
                  </v-autocomplete>
                  <v-autocomplete outlined dense hide-details :label="$t(item.label)" v-model="item.selectedValue" v-else
                    :items="item.listOfValues" item-text="data.name" item-value="_id" class="mb-4" clearable
                    @keyup="debouncedModuleRelationListSearch(item.name, index, $event.target.value)">
                  </v-autocomplete>
                </v-col>
              </v-row>
            </template>
            <template slot="extraButtons" v-if="modelObj.is_task"></template>
          </form-data>
        </template>
        <template v-if="item.id === 1">
          <template v-if="modelObj.is_task">
            <v-row>
              <v-col lg="12" sm="12" class="text-end">
                <span class="caption"> <b>{{ $formatter.formatDate(modelObj.created_at, 'DD.MM.YYYYTHH.mm.ss', `${userDetails.dateformat} HH:mm`) }}</b></span>
                <v-btn :color="systemDetails.themecolor" icon @click="showMailInfoEdit(modelObj.mailbody)" v-if="!showFieldsForTask">
                  <v-icon>mdi-pencil-circle-outline</v-icon>
                </v-btn>
                <template v-if="showFieldsForTask">
                  <v-btn color="success" class="mt-0" icon @click="updateMailValues(modelObj)">
                    <v-icon>mdi-content-save</v-icon>
                  </v-btn>
                  <v-btn  class="mt-0" color="error" icon @click="showFieldsForTask = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
              </v-col>
            </v-row>
            <template v-if="!showFieldsForTask">
              <v-container fluid>
                <div v-html="modelObj.mailbody" style="width: 100%;height: 40%;overflow:hidden;" :style="$vuetify.theme.dark ? 'color: #dacdcd' : ''"
                  class="mb-3 ticketcontent ck ck-content ck-editor__editable ck-rounded-corners ck-blurred">
                </div>
                <v-sheet class="pa-1 custom_rounded-xxl" color="#F5F7FA" v-if="modelObj.hasattachments && modelObj.otherAttachments && modelObj.otherAttachments.length">
                  <v-row class="ma-0">
                    <v-col lg="3" md="4" sm="6" v-for="(other, oIindex) in modelObj.otherAttachments" :key="`other_${oIindex}`" class="pa-1">
                      <v-card width="400px" outlined class="attachment_card" v-resize="setTheAttachmentNameMaxWidth">
                        <v-layout row wrap class="ma-0 pa-1" align-center>
                          <v-sheet color="#DFE3ED" width="40" height="40" :rounded="true" style="display: flex;" class="mr-2">
                            <v-icon class="ma-auto" :color="other.color ? other.color : ''" size="22">{{ other.icon }}</v-icon>
                          </v-sheet>
                          <div style="max-width: 195px;">
                            <p class="subtitle-2 mb-0 text-truncate" :title="other.originalfilename">{{ other.originalfilename }}</p>
                          </div>
                          <v-spacer></v-spacer>
                          <div class="text-truncate pl-1 py-3" :class="$vuetify.breakpoint.smAndDown ? '' : 'show_divider_border_left'">
                            <v-icon color="success" @click="downloadTaskFile(other._id,modelObj.mailid)" size="22"> mdi-download </v-icon>
                            <v-icon color="info" v-if="other.sharepointurl" @click="openAttachment(other.sharepointurl)" size="22"
                              class="mx-1">
                              mdi-eye
                            </v-icon>
                            <template v-else>
                              <v-icon color="primary" v-if="userDetails.issharepointstorage" @click="syncAttachment(other._id,other.mailid)" size="22" class="mx-1">
                                {{ attachmentLoader ? 'mdi-loading mdi-spin' : 'mdi-cloud-sync' }}
                              </v-icon>
                            </template>
                          </div>
                        </v-layout>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-sheet>
              </v-container>
            </template>
            <template v-else>
              <div :class="$vuetify.theme.dark ? 'dark_theme_editor' : ''">
                <ckeditor :editor="editor" v-model="updateMailInfo" :config="editorConfig" ref="editor"></ckeditor>
              </div>
            </template>
          </template>
          <template v-else>
            <v-expansion-panels v-model="panel" multiple v-if="item.id === 1">
              <v-expansion-panel  v-for="(item, index) in ticketMails" :key="index" class="mt-1" @change="toggleExpansionPanel(index)">
                <v-expansion-panel-header hide-actions>
                  <v-row>
                    <v-col cols="1" class="text-left">
                      <v-avatar :color= "systemDetails.themecolor" size="30">
                        <span class="mx-auto white--text caption">
                          <strong>{{ getInitials(item.from.emailaddress.name) }}</strong>
                        </span>
                      </v-avatar>
                    </v-col>
                    <v-col cols="7" class="text-left pl-5">
                      <h3>{{ item.sender.emailaddress.name }} <v-icon v-if="item.hasattachments">mdi-paperclip</v-icon></h3>
                      <p style="display: inline;" class="pt-2">{{ formatedDate(item.reciveddatetime) }} {{ $formatter.formatDate(item.reciveddatetime, 'DD.MM.YYYYTHH:mm:ss', `${userDetails.dateformat} HH:mm`) }}</p><br>
                      <p style="display: inline;" class="mt-2" v-if="!panel.includes(index)"> - {{ $vuetify.breakpoint.xsOnly ? item.bodypreview.slice(0, 25) :  item.bodypreview.slice(0, 100) }} ....</p>
                    </v-col>
                    <v-col cols="4" class="text-end">
                      <v-btn-toggle color="purple">
                        <v-icon class="mr-3" @click.stop="replyMail(item, index)">mdi-reply</v-icon>
                        <v-icon class="mr-3" @click.stop="replyAllMail(item, index)">mdi-reply-all</v-icon>
                        <v-icon @click.stop="forwardMail(item, index)">mdi-forward</v-icon>
                      </v-btn-toggle>
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-divider intense></v-divider>
                    <v-card-text class="pa-0">
                      <v-row class="pa-0">
                        <v-col cols="12" lg="10" class="py-0 px-2">
                          <v-row class="px-0 py-2">
                            <v-col cols="12" lg="6" md="6" sm="6" class="pt-1 mt-5 pa-0" >
                              <strong>{{ $t('from') }} : </strong> <v-chip class="mb-0" small>{{item.from.emailaddress.address}}</v-chip>
                            </v-col>
                            <v-col cols="12" lg="6" md="6" sm="6" class="pt-1 pa-0 mt-2">
                              <strong>{{ $t('to') }} :</strong>
                              <template v-if="item.torecipents && item.torecipents.length">
                                <span v-for="(item,index) in item.torecipents" :key='index'>
                                  <v-chip class="mb-0" small>{{item.emailaddress.address}}</v-chip>
                                </span>
                              </template>
                            </v-col>
                            <v-col cols="12" lg="6" md="6" class="pt-1 pa-0 mt-2"  v-if="item.mailcc && item.mailcc.length">
                              <strong>{{ $t('cc') }} :</strong>
                              <template v-if="item.ccrecipents && item.ccrecipents.length">
                                <span v-for="(item,index) in item.ccrecipents" :key='index'>
                                  <v-chip class="mb-0" small>{{item.emailaddress.address}}</v-chip>
                                </span>
                              </template>
                            </v-col>
                            <v-col cols="12" lg="6" md="6" class="pt-1 pa-0 mt-2"  v-if="item.mailbcc && item.mailbcc.length > 0">
                              <strong>{{ $t('bcc') }} :</strong>
                              <template v-if="item.bccrecipents && item.bccrecipents.length">
                                <span v-for="(item,index) in item.bccrecipents" :key='index'>
                                  <v-chip class="mb-0" small>{{item.emailaddress.address}}</v-chip>
                                </span>
                              </template>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-col cols="12" class="pa-0 mt-8">
                        <v-sheet>
                          <template v-if="!showFieldsForTask">
                            <div v-html="convertHtml(item.body)" style="width: 100%;height: 40%;overflow:hidden;" :style="$vuetify.theme.dark ? 'color: #dacdcd' : ''"
                              class="mb-3 ticketcontent ck ck-content ck-editor__editable ck-rounded-corners ck-blurred">
                            </div>
                          </template>
                          <template v-else>
                            <div :class="$vuetify.theme.dark ? 'dark_theme_editor' : ''">
                              <ckeditor :editor="editor" v-model="updateMailInfo.body" :config="editorConfig" ref="editor"></ckeditor>
                            </div>
                          </template>
                          {{item.otherAttachments}}
                          <v-sheet class="pa-1 custom_rounded-xxl" color="#F5F7FA" v-if="item.hasattachments && item.otherAttachments && item.otherAttachments.length">
                            <v-row class="ma-0" >
                              <v-col lg="3" md="4" sm="6" v-for="(other, oIindex) in item.otherAttachments" :key="`other_${oIindex}`" class="pa-1">
                                <v-card width="400px" outlined class="attachment_card" v-resize="setTheAttachmentNameMaxWidth">
                                  <v-layout row wrap class="ma-0 pa-1" align-center>
                                    <v-sheet color="#DFE3ED" width="40" height="40" :rounded="true" style="display: flex;" class="mr-2">
                                      <v-icon class="ma-auto" :color="other.color ? other.color : ''" size="24">{{ other.icon }}</v-icon>
                                    </v-sheet>
                                    <div style="max-width: 195px;">
                                      <p class="subtitle-2 mb-0 text-truncate" :title="other.name">{{ other.name }}</p>
                                      <span class="caption">{{ $formatter.formatFizeSize(other.size) }}</span>
                                    </div>
                                    <v-spacer></v-spacer>
                                    <div class="text-truncate pl-1 py-3" :class="$vuetify.breakpoint.smAndDown ? '' : 'show_divider_border_left'">
                                      <v-icon color="success" @click="downloadFile(other.id, item.mailid)" size="22"> mdi-download </v-icon>
                                      <v-icon color="info" v-if="other.sharepointurl" @click="openAttachment(other.sharepointurl)" size="22" class="mx-1">
                                        mdi-eye
                                      </v-icon>
                                      <template v-else>
                                        <v-icon color="primary" v-if="userDetails.issharepointstorage" @click="syncAttachment(other.id,item.mailid)" size="22" class="mx-1">
                                          {{ attachmentLoader ? 'mdi-loading mdi-spin' : 'mdi-cloud-sync' }}
                                        </v-icon>
                                      </template>
                                    </div>
                                  </v-layout>
                                </v-card>
                              </v-col>
                            </v-row>
                          </v-sheet>
                        </v-sheet>
                      </v-col>
                    </v-card-text>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
          </template>
        </template>
        <template v-if="item.id === 2">
          <comments-section :ticketNumber="modelObj.number" :assignedto="modelObj.assignedto" :listOfComments="listOfComments" :ticketObj="modelObj" v-if="tab === 2"></comments-section>
        </template>
        <template v-if="item.id === 3">
          <history v-if="tab === 3" :ticketId="modelObj._id"></history>
        </template>
        <template v-if="item.id === 4">
          <notes v-if="tab === 4" :modelObj="modelObj"></notes>
        </template>
        <template v-if="item.id === 5">
          <reminder v-if="tab === 5" :ticketObj="modelObj"></reminder>
        </template>
        <template v-if="item.id === 6">
          <activities v-if="tab === 6" :ticketObj="modelObj"></activities>
        </template>
      </v-tab-item>
    </v-tabs-items>
    <!-- DELETE WARNING -->
    <v-dialog v-model="showDeleteDialog" persistent max-width="400px" class="mt-0">
      <v-card>
        <v-card-title class="py-2 px-4 error white--text">
          {{ $t('confirmation') }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-3 pt-0 pb-1">
          <v-checkbox v-model="isDeleteMails" :label="$t('deleteMailsTo')"></v-checkbox>
          <span v-if="isDeleteMails" color="error">
          {{$t('note')}} : <code>{{$t('deleteNote')}}</code>
          </span>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small color="error" id="priority" @click="deleteConfirmed">{{ $t('delete') }}</v-btn>
          <v-btn small color="info" id="priorityclose" @click="showDeleteDialog=false,isDeleteMails=false">{{ $t('close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Status close confirm -->
    <v-dialog v-model="cancloseDialog" persistent max-width="400px" class="mt-0">
      <v-card>
        <v-card-title>
          {{ $t('confirmation') }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-5">
          <h4> {{ $t('noUserAssigned') }}</h4>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="info" @click="setAssingedTo()" :loading="cancloseLoading">{{ $t('yes') }}</v-btn>
          <v-btn color="error" @click="closeConfirmDialog">{{ $t('close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Attachmets -->
    <v-dialog v-model="attachmentsDialog" persistent width="800" class="mt-0">
      <v-card>
        <v-card-title :style="`background-color:${systemDetails.themecolor};color:${systemDetails.textcolor}`" class="pa-3 pb-2 title">
         {{ $t('attachments') }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-5">
          <sharepoint-tree-view @copyDocuments="getAttachmentFiles" v-if="attachmentsDialog && typeOfFileInput === 2"></sharepoint-tree-view>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="info" @click="includeFiles()" :loading="cancloseLoading">{{ $t('ok') }}</v-btn>
          <v-btn color="error" @click="closeAttachmentsDialog">{{ $t('close') }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="replyOrForwardDialog" persistent fullscreen class="mt-0">
      <v-card flat>
        <v-card-title :style="`background-color:${systemDetails.themecolor};color:${systemDetails.textcolor}`" class="pa-1 pb-2 title">
          <v-btn icon :color="systemDetails.textcolor" @click="replyOrForwardDialog = false" class="mx-2"><v-icon>mdi-close-circle-outline</v-icon></v-btn>
          {{ $t('send') }}
          <v-flex class="text-end">
            <v-btn icon :color="systemDetails.textcolor" @click="sendMail(replyForwardItem, replyForwardItem.index)" :loading="sendMailLoading" class="mx-2"><v-icon>mdi-send</v-icon></v-btn>
            <input type="file" multiple name="fields[assetsFieldHandle][]" id="assetsFieldHandle" style="display:hidden"
              class="w-px h-px opacity-0 overflow-hidden absolute" @change="onChange" ref="file"/>
            <v-btn icon :color="systemDetails.textcolor" @click="pickFile()" :loading="sendMailLoading" class="mx-2"><v-icon>mdi-paperclip</v-icon></v-btn>
            <v-btn icon :color="systemDetails.textcolor" @click="openAttachmentsDialog(replyForwardItem.index, 2)" :loading="sendMailLoading" class="mx-2"><v-icon>mdi-microsoft-sharepoint</v-icon></v-btn>
          </v-flex>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-3">
          <v-form ref="mailForm">
            <v-row no-gutters>
              <v-col cols="12" lg="9" md="9" sm="6">
                <div class="combo">
                  <v-combobox v-model="replyForwardItem.mailTo" :items="listOfEmailSuggestions" hide-selected  :rules="$_multiSelectValidation" :label="$t('to')" multiple
                    persistent-hint small-chips outlined dense hide-details class="mb-1" item-text="nameAndEmail" item-value="emailaddress"
                    :search-input.sync="replyForwardItem.mailToFieldSearchText" :return-object="false" :loading="replyForwardItem.mailToEmailSuggestionloading"
                    @keyup="![13, 16, 17, 18, 20, 27, 30, 32, 37, 38, 39, 40].includes($event.keyCode) ? debouncedGetEmailSuggestions(replyForwardItem, replyForwardItem.index, 'mailTo') : () => {}"></v-combobox>
                </div>
              </v-col>
              <v-col cols="12" lg="9" md="9" sm="6">
                <div class="combo">
                  <v-combobox v-model="replyForwardItem.mailCc" :items="listOfEmailSuggestions" hide-selected  :label="$t('cc')" multiple persistent-hint
                    small-chips  outlined dense hide-details class="mb-1" item-text="nameAndEmail" item-value="emailaddress"
                    :search-input.sync="replyForwardItem.mailCcFieldSearchText" :return-object="false" :loading="replyForwardItem.mailCcEmailSuggestionloading"
                    @keyup="![13, 16, 17, 18, 20, 27, 30, 32, 37, 38, 39, 40].includes($event.keyCode) ? debouncedGetEmailSuggestions(replyForwardItem, replyForwardItem.index, 'mailCc') : () => {}"></v-combobox>
                </div>
              </v-col>
              <v-col cols="12" lg="9" md="9" sm="6">
                <div class="combo">
                  <v-combobox v-model="replyForwardItem.mailBcc" :items="listOfEmailSuggestions" hide-selected  :label="$t('bcc')" multiple persistent-hint
                    small-chips outlined dense hide-details class="mb-1" item-text="nameAndEmail" item-value="emailaddress"
                    :search-input.sync="replyForwardItem.mailBccFieldSearchText" :return-object="false" :loading="replyForwardItem.mailBccEmailSuggestionloading"
                    @keyup="![13, 16, 17, 18, 20, 27, 30, 32, 37, 38, 39, 40].includes($event.keyCode) ? debouncedGetEmailSuggestions(replyForwardItem, replyForwardItem.index, 'mailBcc') : () => {}"></v-combobox>
                </div>
              </v-col>
              <v-col cols="12">
                <v-sheet class="custom_rounded-xxl" color="#F5F7FA" v-if="(replyForwardItem.listOfOtherFiles && replyForwardItem.listOfOtherFiles.length)">
                  <v-layout row wrap class="ma-0">
                    <v-flex lg3 md4 sm6 v-for="(files, fileIndex) in replyForwardItem.listOfOtherFiles" :key="`file_${fileIndex}`" class="py-1 pl-3 pr-0">
                        <v-card outlined>
                          <v-layout row wrap class="ma-0 pa-1">
                            <v-flex lg1 xs1>
                              <v-sheet color="#DFE3ED" width="20" height="20" :rounded="true" style="display: flex;">
                                <v-icon class="ma-auto" :color="files.color" size="15"> {{ $formatter.mimeTypeOfDocument(files.type).icon }} </v-icon>
                              </v-sheet>
                            </v-flex>
                            <v-flex lg9 xs10>
                              <p class="subtitle-2 mb-0  text-truncate" :title="files.name">{{ files.name }}</p>
                            </v-flex>
                            <v-flex lg2 xs1 class="text-center">
                              <v-icon color="error" @click="removeAttachment(fileIndex, 'listOfOtherFiles', replyForwardItem.index)" size="18"> mdi-close-circle-outline </v-icon>
                            </v-flex>
                          </v-layout>
                        </v-card>
                      </v-flex>
                  </v-layout>
                </v-sheet>
              </v-col>
            </v-row>
            <v-row>
              <v-col :class="$vuetify.theme.dark ? 'dark_theme_editor' : ''">
                <span v-if="modelObj.is_task">{{$t('description')}}</span>
                <span v-else>{{$t('body')}}</span>
                <ckeditor :editor="editor" v-model="replyForwardItem.mailBody" :config="editorConfig" ref="editor"></ckeditor>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex'
import CommonFunctions from './mixin'
import Editor from '@/ckeditor'
import moment from 'moment'
export default {
  mixins: [CommonFunctions],
  data () {
    return {
      modelObj: {
        _id: this.$route.params.ticket_id
      },
      ticketTerm: null,
      bodySignature: null,
      enableEditMode: false,
      mailsMerged: 0,
      MODULE_URL: 'tickets',
      ticketMails: [],
      editor: Editor,
      editorConfig: {
        removePlugins: ['ElementsPath'],
        sharedSpaces: {
          top: 'toolbarLocation'
        },
        toolbar: {
          items: ['bold', 'italic', 'outdent', 'indent', '|', 'bulletedList', 'numberedList', '|', 'undo', 'redo']
        }
      },
      noteConfig: {
        height: '250px',
        sharedSpaces: {
          top: 'toolbarLocation'
        },
        toolbar: {
          items: ['bold', 'italic', 'outdent', 'indent', '|', 'bulletedList', 'numberedList', '|', 'insertTable', 'blockQuote', 'undo', 'redo']
        }
      },
      notesObj: {
        _id: '',
        notes: ''
      },
      panel: [0],
      tab: null,
      sendMailLoading: false,
      isDeleteMails: false,
      showDeleteDialog: false,
      toggle_exclusive: 2,
      emailRegex: /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/,
      listOfNotes: [],
      fav: true,
      menu: false,
      message: false,
      hints: true,
      showNoteBox: false,
      selected: [],
      NOTES_MODULE: 'ticketnotes',
      backupTicket: {},
      isLoading: false,
      listOfComments: [],
      listOfAccounts: [],
      accountLoading: false,
      /* pwa */
      isShowUpdate: true,
      fieldsLoading: false,
      showReplyForwardDialog: false,
      actionType: 0, // reply-1, replyall-2, forward-3
      selectedMailItem: null, // for reply,replyall & forward
      showReplyForwardMenu: false,
      showReplyForwardMenuWidth: 300,
      enableSearch: true,
      showFieldsForTask: false,
      updateMailInfo: {},
      modulesRelated: [],
      cancloseLoading: false,
      cancloseDialog: false,
      userSignature: '',
      attachmentsURL: process.env.VUE_APP_ATTACHMENTS,
      listOfFiles: [],
      listOfOtherFiles: [],
      attachmentsList: [],
      imagesTypes: ['image/bmp', 'image/png', 'image/jpg', 'image/jpeg'],
      attachmentsFiles: [],
      projectLoader: false,
      listOfProjects: [],
      reRenderNotes: 0,
      showConvertSale: false,
      debouncedGetEmailSuggestions: null,
      listOfEmailSuggestions: [],
      filelist: [],
      sharepoint: {},
      hideDragFile: false,
      noFileSelect: false,
      attachmentsDialog: false,
      currentItem: null,
      typeOfFileInput: null,
      loaderStatus: true,
      attachmentFileNameMaxWidth: 150,
      saveNotesLoader: false,
      attachmentLoader: false,
      isForwardMail: false,
      updateLoading: false,
      replyOrForwardDialog: false,
      replyForwardItem: {},
      debouncedModuleRelationListSearch: null
    }
  },
  components: {
    'form-data': () => import('@/components/FormTemplate.vue'),
    'comments-section': () => import('./Comments.vue'),
    history: () => import('./History.vue'),
    notes: () => import('./Notes.vue'),
    reminder: () => import('./Reminder.vue'),
    activities: () => import('./Activities.vue'),
    'sharepoint-tree-view': () => import('./SharepointTreeView.vue')
  },
  computed: {
    ...mapGetters(['formType', 'getUsers', 'getListOfTicketPriorities', 'getListOfTicketStatus', 'getListOfTicketCategory', 'getsignalRConnection', 'getListOfGroups', 'userDetails', 'listOfModules', 'systemDetails']),
    formReferences () {
      return {
        title: '',
        properties: [{
          model: 'account_id',
          live_search_model: 'accountSearch',
          type: this.formType.AUTO_COMPLETE_LIVE,
          items: this.listOfAccounts,
          select_text: 'data.name',
          select_value: '_id',
          class: 'xs12 sm6',
          label: this.$t('account'),
          loading: this.accountLoading,
          change: this.loadValues
        }, {
          model: 'assignedto',
          type: this.formType.AUTO_COMPLETE,
          rules: [],
          items: this.getUsersAndGroups,
          select_text: 'name',
          select_value: 'alternateid',
          change: this.changeStatus,
          label: this.$t('assignedTo'),
          class: 'xs12 sm6'
        }, {
          model: 'priority',
          type: this.formType.AUTO_COMPLETE,
          rules: [],
          items: this.getListOfTicketPriorities,
          select_text: 'name',
          select_value: '_id',
          label: this.$t('changePriority'),
          class: 'xs12 sm6'
        }, {
          model: 'status',
          type: this.formType.AUTO_COMPLETE,
          rules: [],
          items: this.getListOfTicketStatus,
          select_text: 'name',
          select_value: '_id',
          label: this.$t('status'),
          class: 'xs12 sm6'
        }, {
          model: 'category_id',
          type: this.formType.AUTO_COMPLETE,
          items: this.getListOfTicketCategory,
          select_text: 'name',
          select_value: '_id',
          class: 'xs12 sm6',
          label: this.$t('category')
        }
        // , {
        //   model: 'tags',
        //   type: this.formType.COMBOBOX,
        //   rules: [],
        //   multiple: true,
        //   items: [],
        //   label: this.$t('tags'),
        //   class: 'sm12'
        // }
        ],
        buttons: [{
          name: 'update_ticket',
          color: 'success',
          label: this.$t('update'),
          click: () => this.checkStatusClosed(),
          // eslint-disable-next-line
          loading: this.updateLoading,
          is_show: true
        }, {
          name: 'delete_ticket',
          color: 'error',
          label: this.$t('delete'),
          click: () => this.deleteHandler(),
          loading: false,
          is_show: true
        }, {
          name: 'convert_ticket',
          color: 'info',
          label: this.modelObj.is_task ? this.$t('convertToTicket') : this.$t('convertToTask'),
          click: () => this.convertTypeHandler(),
          is_show: true,
          loading: false
        }, {
          name: 'convert_sale',
          color: 'info',
          class: 'mt-3',
          label: this.$t('convertToSale'),
          click: () => this.convertToSale(),
          is_show: this.showConvertSale,
          loading: false
        }]
      }
    },
    ticketActions () {
      return [
        { icon: 'mdi-reply', label: this.$t('reply'), click: (item, index) => this.replyMail(item, index), is_show: true },
        { icon: 'mdi-reply-all', label: this.$t('replyAll'), click: (item, index) => this.replyAllMail(item, index), is_show: true },
        { icon: 'mdi-forward', label: this.$t('forward'), click: (item, index) => this.forwardMail(item, index), is_show: !this.modelObj.is_task }
      ]
    },
    getUsersAndGroups () {
      const userList = this.$formatter.cloneVariable(this.getUsers)
      const groupList = this.$formatter.cloneVariable(this.getListOfGroups)
      const list = [...userList, ...groupList]
      return list
    },
    tabs () {
      return [
        { id: 0, name: this.$t('details'), icon: 'mdi-file' },
        { id: 1, name: this.$t('conversation'), icon: 'mdi-email' },
        { id: 2, name: this.$t('comments'), icon: 'mdi-comment' },
        { id: 3, name: this.$t('history'), icon: 'mdi-history' },
        { id: 4, name: this.$t('personalNotes'), icon: 'mdi-note' },
        { id: 5, name: this.$t('reminder'), icon: 'mdi-alarm' },
        { id: 6, name: this.$t('activities'), icon: 'mdi-calendar' }
      ]
    },
    getAccountSubModules () {
      if (this.listOfModules && this.listOfModules.length) {
        const accountModule = this.listOfModules.find(x => x.name === this.ACCOUNT)
        const accountSubmodules = accountModule ? (accountModule.submodule || []) : []
        return accountSubmodules
      } else return []
    }
  },
  watch: {
    'modelObj.accountSearch' (val) {
      if (val === undefined) return
      if (val && !val.includes('-') && this.enableSearch) this.loadAccountList(val)
    },
    // '$vuetify.breakpoint': {
    //   deep: true,
    //   handler () {
    //     this.showReplyForwardForm()
    //   }
    // },
    'showReplyForwardMenu' (val) {
      if (val) document.getElementsByTagName('BODY')[0].parentElement.classList.add('noscroll')
      else document.getElementsByTagName('BODY')[0].parentElement.classList.remove('noscroll')
    }
  },
  mounted () {
    this.debouncedGetEmailSuggestions = this.$formatter.debounce(this.getEmailSuggestions, 500)
    this.debouncedModuleRelationListSearch = this.$formatter.debounce(this.getRelatedList, 500)
    const domain = window.localStorage.getItem('businessflow365PWAdomain')
    this.attachmentsURL = domain ? `${this.attachmentsURL}${domain}/` : this.attachmentsURL
    this.fieldsLoading = true
    this.modulesRelated = []
    const modulesRelated = this.listOfModules.filter(x => x.include_ticket && x.isactive && (x.name !== this.ACCOUNT)) // since account is hard coded hide from here
    this.modulesRelated = modulesRelated
    if (this.modulesRelated.length > 0) {
      const hasSale = this.modulesRelated.find(x => x.name === this.SALE)
      let hasSaleManagePermission = false
      const hasSalePermission = this.listOfModules.find(x => x.name === this.SALE)
      if (hasSalePermission) hasSaleManagePermission = hasSalePermission.hasManagePermission
      this.showConvertSale = hasSale && hasSaleManagePermission
    }
    this.getTicketTerm()
    this.getListOfNotes()
    this.$store.dispatch('getUsers')
    this.$store.dispatch('getListOfGroups')
    this.$store.dispatch('getTicketPriority')
    this.$store.dispatch('getTicketStatus')
    this.$store.dispatch('getTicketCategory')
    const text = this.$t('deleteMsg')
    this.$eventBus.$on('deleteItems', (data) => {
      if (data.module === this.NOTES_MODULE) {
        const payload = { ids: data.ids, url: `${this.NOTES_MODULE}/delete_multiple`, module: this.NOTES_MODULE }
        this.$store.commit('showDeleteDialog', payload)
      }
    })
    // this.$root.$on('loadComments', (data) => {
    //   this.getAllComments(data)
    // })
    this.$eventBus.$on('deleteSuccess', (data) => {
      if (data === this.NOTES_MODULE) {
        this.selected = []
        this.$root.$emit('snackbar', { snackbar: true, color: 'success', text })
        this.$store.commit('hideDialog')
        this.getListOfNotes()
      }
    })
  },
  methods: {
    setIframeHeight () {
      setTimeout(() => {
        for (let index = 0; index < this.ticketMails.length; index++) {
          const iframe = document.getElementById('content-frame' + index)
          iframe.onload = function () {
            iframe.style.height = (iframe.contentWindow.document.body.scrollHeight + 50) + 'px'
          }
        }
      }, 1)
    },
    singularPluralData (value) {
      let msg = `${this.$t('merged')} ${value}`
      msg += value > 1 ? this.$t('mails') : this.$t('mail')
      return msg
    },
    changeStatus () {
      // if (this.modelObj.status === parseInt(process.env.VUE_APP_TICKET_STAUS_NEW)) this.modelObj.status = parseInt(process.env.VUE_APP_TICKET_STAUS_INPROGRESS)
    },
    addToSelected (event, item) {
      if (event) {
        this.selected.push(item)
      } else {
        const result = this.selected.filter(x => x._id !== item._id)
        if (result) {
          this.selected = result
        }
      }
    },
    deleteNotes () {
      const ids = this.selected.map(x => x._id)
      const data = { module: this.NOTES_MODULE, ids }
      this.$eventBus.$emit('deleteItems', data)
    },
    backToList () {
      this.modulesRelated = []
      const hasHistory = window.localStorage.getItem('pathHistory')
      if (hasHistory && hasHistory !== 'null') {
        window.localStorage.setItem('pathHistory', null)
        this.$router.push(hasHistory)
      } else this.$router.push(`/${this.MODULE_URL}`)
    },
    toggleEditMode () {
      this.enableEditMode = !this.enableEditMode
    },
    supportingList (type, text) {
      text = this.$refs.ticketViewEditReference.$refs[text][0].lazySearch
      const model = { module: type, dialog: true, text }
      this.$store.commit('supportingList', model)
    },
    getListOfNotes () {
      const query = [{ $match: { $and: [{ Ticket_Id: `${this.modelObj._id}` }, { User_Id: `${this.userDetails._id}` }] } }]
      this.$api.execute('post', '/ticketnotes/query', query).then(response => {
        this.listOfNotes = response.data
        this.listOfNotes.forEach(element => {
          element.showEdit = false
        })
      })
    },
    loadAccountList (searchTerm) {
      this.accountLoading = true
      this.listOfAccounts = []
      const model = {
        searchterm: searchTerm || ''
      }
      this.$api.execute('get', `moduledata/${this.ACCOUNT}/search?searchterm=${model.searchterm}`).then((response) => {
        this.listOfAccounts = response.data
      }).finally(() => {
        this.accountLoading = false
      })
    },
    saveNotes () {
      const model = {
        _id: this.$formatter.cloneVariable(this.notesObj._id) ? this.$formatter.cloneVariable(this.notesObj._id) : undefined,
        ticket_id: this.modelObj._id,
        user_id: this.userDetails._id,
        note: this.$formatter.cloneVariable(this.notesObj.notes)
      }
      this.$api.execute(model._id ? 'put' : 'post', model._id ? `/ticketnotes/${model._id}` : '/ticketnotes', model).then(response => {
        this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'savedSuccess' })
        // if (this.modelObj.assignedto) {
        //   let assignedTo = this.$formatter.cloneVariable(this.modelObj.assignedto)
        //   assignedTo = assignedTo.split('_')
        //   if (assignedTo[0] === 'group') {
        //     let item = this.getListOfGroups.find(x => x._id == assignedTo[1])
        //     let usersarray = []
        //     // let query = {
        //     //   filter: `groupid eq ${assignedTo[1]} and isactive eq 1`
        //     // }
        //     let  msg = `${this.$store.state.auth.userDetails.name} ${ model._id ? this.$t('updated') : this.$t('added') } ${this.$t('noteIn')} ${this.modelObj.number}`
        //     this.$api.execute('get', `usergroups/get_by_group/${assignedTo[1]}`).then(result => {
        //       usersarray = result.data.map(x => x.user_id)
        //     }).finally(() => {
        //       this.getsignalRConnection.invoke('SendNotification', usersarray, msg, response.data._id)
        //     })
        //   } else {
        //     if (this.$store.state.auth.userDetails._id != assignedTo[1]) {
        //       let  msg = `${this.$store.state.auth.userDetails.name} ${ model._id ? this.$t('updated') : this.$t('added') } ${this.$t('noteIn')} ${this.modelObj.number}`
        //       this.getsignalRConnection.invoke('SendNotification', [assignedTo[1]], msg, model.ticket_id)
        //     }
        //   }
        // }
        this.showNoteBox = false
        this.notesObj = {}
        this.getListOfNotes()
      })
    },
    editNotes (index) {
      const note = this.$formatter.cloneVariable(this.listOfNotes[index])
      this.notesObj._id = note._id
      this.notesObj.notes = note.note
      this.showNoteBox = true
    },
    getSingleRecordHandler (fromMailActions) {
      const query = [
        { $addFields: { convertedId: { $toString: '$_id' } } },
        { $match: { $and: [{ convertedId: `${this.modelObj._id}` }] } },
        {
          $lookup: {
            let: { accountId: '$Account_Id' },
            from: 'Account',
            pipeline: [{ $match: { $expr: { $eq: ['$_id', { $convert: { input: '$$accountId', to: 'objectId', onError: { error: true }, onNull: { isnull: true } } }] } } }, { $project: { _id: 1, Data: 1 } }],
            as: 'account_info'
          }
        },
        {
          $addFields: {
            ticketId: {
              $toString: '$_id'
            }
          }
        },
        {
          $lookup: {
            from: 'MailAttachment',
            localField: 'ticketId',
            foreignField: 'MailId',
            as: 'attachments'
          }
        },
        { $lookup: { from: 'Mail', localField: 'MailId', foreignField: 'MailId', as: 'mail_info' } },
        { $lookup: { let: { mailboxid: '$MailboxId' }, from: 'MailConfiguration', pipeline: [{ $match: { $expr: { $eq: ['$_id', { $convert: { input: '$$mailboxid', to: 'objectId' } }] } } }], as: 'mailbox' } }
      ]
      this.$api.execute('post', `${this.MODULE_URL}/query`, query).then(response => {
        const result = response.data[0]
        if (result && result.tags) result.tags = JSON.parse(result.tags)
        if (result.assignedto) {
          result.assignedto = `user_${result.assignedto}`
        } else if (result.assignedto_group) {
          result.assignedto = `group_${result.assignedto_group}`
        }
        this.backupTicket = this.$formatter.cloneVariable(result)
        this.modelObj = result
        this.modelObj = result
        this.modelObj.imageAttachments = []
        this.modelObj.otherAttachments = []
        if (this.modelObj.attachments && this.modelObj.attachments.length > 0) {
          this.modelObj.attachments.forEach(attachment => {
            const getExtensionType = this.mimeTypeOfDocument(attachment.extension)
            this.modelObj.otherAttachments.push({ ...attachment, icon: getExtensionType.icon, color: getExtensionType.color })
          })
        }
        if (result.account_info.length > 0) {
          this.enableSearch = false
          this.listOfAccounts.push(result.account_info[0])
          this.modelObj.account_id = result.account_id
        }
        setTimeout(() => {
          // const modulesRelated = this.listOfModules.filter(x => x.include_ticket && x.name !== this.ACCOUNT && x.isactive)
          // this.modulesRelated = modulesRelated
          // if (this.modulesRelated.length > 0) {
          //   const hasSale = this.modulesRelated.find(x => x.name === this.SALE)
          //   let hasSaleManagePermission = false
          //   const hasSalePermission = this.listOfModules.find(x => x.name === this.SALE)
          //   if (hasSalePermission) hasSaleManagePermission = hasSalePermission.hasManagePermission
          //   this.showConvertSale = !!hasSale && !!hasSaleManagePermission
          // }
          this.enableSearch = true
          // this.loadValues()
          // if (this.modelObj.modulerelations) {
          //   this.modulesRelated.forEach(element => {
          //     const result = this.modelObj.modulerelations.find(x => x.module === element.name)
          //     if (result) {
          //       element.selectedValue = result._id
          //     }
          //   })
          // }
        }, 200)
        if (!this.modelObj.is_task) {
          this.fieldsLoading = true
          if (fromMailActions) {
            setTimeout(() => {
              this.getTicketMails()
            }, 2000)
          } else this.getTicketMails()
        }
        const currentUser = this.getUsers.find(x => x._id === this.userDetails._id)
        this.bodySignature = `<p></p><p></p><p><strong>[${this.ticketTerm ? this.ticketTerm : ''}#${this.modelObj.number}]</strong>${currentUser && currentUser.signature ? '<iframe id="userSignFooter" frameborder="0" width="100%"></iframe>' : ''}</p>`
        // this.getAllComments(this.modelObj._id)
        // this.bodySignature = `<p></p><strong>[${this.ticketTerm ? `${this.ticketTerm}` : ''}#${this.modelObj.number}]</strong><iframe id="userSignFooter" frameborder="0" width="100%"></iframe>`
        // this.bodySignature = `<p></p><p></p><strong>[${this.ticketTerm ? `${this.ticketTerm} ` : ''}#${this.modelObj.number}]</strong>`
      }).finally(() => {
        this.loadModuleRelationsSelectedItems()
        this.fieldsLoading = false
      })
    },
    loadModuleRelationsSelectedItems () {
      if (this.modelObj.modulerelations && this.modelObj.modulerelations.length) {
        const payloadList = []
        const listGroupedByModule = this.$formatter.groupBy(this.modelObj.modulerelations, 'module')
        Object.keys(listGroupedByModule).forEach(moduleName => {
          if (moduleName === this.PROJECT) this.getProjectById(this.modelObj)
          else {
            const obj = {
              modulename: moduleName,
              ids: listGroupedByModule[moduleName].map(x => x._id).filter(x => x)
            }
            payloadList.push(obj)
          }
        })
        this.$api.execute('post', 'moduledata/Default/get_certain_records_by_module', payloadList).then(({ data: moduleResultList }) => {
          /* Set values in custom module fields */
          if (moduleResultList.length > 0) {
            this.modulesRelated.forEach((element, index) => {
              if (this.modelObj.modulerelations && this.modelObj.modulerelations.length > 0) {
                const resultrelation = this.modelObj.modulerelations.find(x => x.module === element.name)
                if (resultrelation) {
                  element.selectedValue = resultrelation._id
                }
              }
              const obj = moduleResultList.find(x => x.modulename === element.name && (x.records && x.records.length))
              if (obj && obj.records.length) {
                const result = obj.records || []
                result.forEach(item => {
                  item.data.name = `${item.data.name || ''} ${item.data.last_name || ''}`
                })
                this.$set(this.modulesRelated, index, { ...this.modulesRelated[index], listOfValues: result })
              }
            })
          }
        })
      }
    },
    closeConfirmDialog () {
      this.cancloseDialog = false
      this.cancloseLoading = false
    },
    getTicketTerm () {
      this.$api.execute('get', 'systemconfigurations')
        .then(({ data }) => {
          if (data) this.ticketTerm = (data.systemconfiguration && data.systemconfiguration.ticketterm) ? data.systemconfiguration.ticketterm : ''
          this.getSingleRecordHandler()
          this.enableEditMode = true
        })
    },
    getTicketMails () {
      const obj = {
        ticketid: this.modelObj._id,
        mailboxid: this.$route.query.mailboxid,
        conversationid: this.$route.query.conversationid
      }
      this.$api.execute('post', 'mails/get_all', obj).then((response) => {
        if (response.data.length === 0) {
          this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'Mail does not exist in outlook !!' })
        } else {
          this.subject = response.data[0].subject
          let mergedMailsCount = 0
          const mails = this.$formatter.cloneVariable(response.data)
          const tempAllMails = []
          mails.forEach(mail => {
            if (mail.mergedmails && mail.mergedmails.length) {
              mergedMailsCount += mail.mergedmails.length
              tempAllMails.push(...mail.mergedmails)
              mail.mergedmails = null
            }
            tempAllMails.push(mail)
          })
          const uniqueItemFound = []
          tempAllMails.sort((a, b) => {
            if (a.mailid === b.mailid) uniqueItemFound.push(tempAllMails.indexOf(a))
          })
          uniqueItemFound.forEach(index => {
            tempAllMails.splice(index, 1)
          })
          var clonedArray = tempAllMails.map(function (mail) {
            mail.showMailCc = false
            mail.showMailBcc = false
            mail.mailcc = mail.ccrecipents
            mail.mailbcc = mail.bccrecipents
            mail.isShowMailForm = false
            return mail
          })
          clonedArray.sort((a, b) => {
            const date1 = a.datetime
            const date2 = b.datetime
            return this.$formatter.sortWithDates(date2, date1, 'DD.MM.YYYYTHH:mm:ss')
          })
          clonedArray.forEach(element => {
            element.imageAttachments = []
            element.otherAttachments = []
            if (element.attachments && element.attachments.length > 0) {
              element.attachments.forEach(attachment => {
                const getExtensionType = this.mimeTypeOfDocument(attachment.extension)
                // if (getExtensionType === 'mdi-file-image') {
                //   element.imageAttachments.push({ ...attachment, icon: getExtensionType, color: '' });
                // } else element.otherAttachments.push({ ...attachment, icon: getExtensionType.icon, color: getExtensionType.color });
                element.otherAttachments.push({ ...attachment, icon: getExtensionType.icon, color: getExtensionType.color })
              })
            }
          })
          this.mailsMerged = mergedMailsCount
          this.ticketMails = this.$formatter.cloneVariable(clonedArray).reverse()
          if (obj != null) {
            this.getAttachmentById(0)
          }
        }
      }).finally(() => {
        var imgs = document.getElementsByTagName('img')
        for (var i = 0; i < imgs.length; i++) {
          imgs[i].style.removeProperty('zoom')
        }
        // if (document.getElementsByTagName("img").style) {
        //   document.getElementsByTagName("img").removeAttribute("style");
        //   document.getElementsByTagName("img").removeAttribute("style");
        // }
      })
    },
    checkStatusClosed () {
      const model = this.$formatter.cloneVariable(this.modelObj)
      if (!model.assignedto && !model.assignedto_group) {
        const statusItem = this.getListOfTicketStatus.find(x => x._id === model.status)
        if (statusItem && statusItem.canclose) {
          this.cancloseDialog = true
          // this.statusItem = item
        } else this.saveUpdateHandler()
      } else {
        this.saveUpdateHandler()
      }
    },
    setAssingedTo () {
      this.cancloseLoading = true
      this.modelObj.assignedto = `user_${this.userDetails._id}`
      this.cancloseDialog = false
      this.cancloseLoading = false
      this.saveUpdateHandler()
    },
    async saveUpdateHandler (type) {
      if (this.$refs.ticketViewEditReference[0].$refs.validateForm.validate()) {
        this.updateLoading = true
        const model = this.$formatter.cloneVariable(this.modelObj)
        if (this.listOfFiles.length > 0 || this.listOfOtherFiles.length > 0) model.attachments = await this.saveAttachmentsHandler()
        const modelRelatedModule = this.$formatter.cloneVariable(this.modulesRelated)
        model.modulerelations = []
        // If any module has ticket relation
        if (modelRelatedModule.length > 0) {
          model.modulerelations = modelRelatedModule.map(x => { return { id: x.selectedValue, module: x.name } })
        }
        model.tags = model.tags ? JSON.stringify(model.tags) : ''
        let assignedTo = []
        const assignedToBackup = model.assignedto
        if (model.assignedto) {
          assignedTo = model.assignedto.split('_')
          if (assignedTo[0] === 'user') {
            model.assignedto = assignedTo[1]
            model.assignedto_group = null
          } else {
            model.assignedto = null
            model.assignedto_group = assignedTo[1]
          }
        }
        const url = this.MODULE_URL
        this.$api.saveUpdateHandler(url, model).then((response) => {
          this.$root.$emit('snackbar', { ...response.snackbar, text: response.snackbar.text })
          let msg = ''
          if (assignedTo.length > 0) {
            if (model.status !== this.backupTicket.status) {
              const oldStatus = this.getListOfTicketStatus.find(x => x._id === this.backupTicket.status)
              const newStatus = this.getListOfTicketStatus.find(x => x._id === model.status)
              if (!oldStatus) msg = `${this.$t('statusSet')} ${newStatus.name} ${this.$t('for')} ${this.backupTicket.number} ${this.$t('by')} ${this.userDetails.name}`
              else msg = `${this.$t('statusChanged')} ${oldStatus.name} ${this.$t('to')} ${newStatus.name} ${this.$t('for')} ${this.backupTicket.number} ${this.$t('by')} ${this.userDetails.name}`
            } else if (assignedToBackup !== this.backupTicket.assignedto) {
              if (assignedTo[0] === 'user') msg = `${this.$t('assignedtoMessage')} ${this.backupTicket.number}  ${this.$t('by')} ${this.userDetails.name}`
              else msg = this.sendAssignedNotification(this.backupTicket.number, assignedTo[1])
            } else if (model.priority !== this.backupTicket.priority) {
              const oldPriority = this.getListOfTicketPriorities.find(x => x._id === this.backupTicket.priority)
              const newPriority = this.getListOfTicketPriorities.find(x => x._id === model.priority)
              if (!oldPriority) msg = `${this.$t('prioritySet')} ${newPriority.name} ${this.$t('for')} ${this.backupTicket.number}  ${this.$t('by')} ${this.userDetails.name}`
              else msg = `${this.$t('priorityChanged')} ${oldPriority.name} ${this.$t('to')} ${newPriority.name} ${this.$t('for')} ${this.backupTicket.number}  ${this.$t('by')} ${this.userDetails.name}`
            } else if (model.category_id !== this.backupTicket.category_id) {
              const oldCategory = this.getListOfTicketCategory.find(x => x._id === this.backupTicket.category_id)
              const newCategory = this.getListOfTicketCategory.find(x => x._id === model.category_id)
              if (!oldCategory) msg = `${this.$t('categorySet')} ${newCategory.name} ${this.$t('for')} ${this.backupTicket.number}  ${this.$t('by')} ${this.userDetails.name}`
              else msg = `${this.$t('categoryChanged')} ${oldCategory.name} ${this.$t('to')} ${newCategory.name} ${this.$t('for')} ${this.backupTicket.number}  ${this.$t('by')} ${this.userDetails.name}`
            }
            if (assignedTo[0] === 'group') {
              // const usersarray = []
              // let query = { filter: `groupid eq ${assignedTo[1]} and isactive eq 1` }
              // this.$api.execute('get', `usergroups/get_by_group/${assignedTo[1]}`).then(result => {
              //   usersarray = result.data.map(x => x.user_id)
              // }).finally(() => {
              //   this.getsignalRConnection.invoke('SendNotification', usersarray, msg, response._id)
              // })
            } else {
              if (assignedTo[1] !== this.userDetails._id) this.getsignalRConnection.invoke('SendNotification', [assignedTo[1]], msg, response._id)
            }
          }
        }).finally(() => {
          this.updateLoading = false
          this.getSingleRecordHandler()
        })
      } else this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'validationIssue' })
    },
    async saveAttachmentsHandler () {
      var formData = new FormData()
      var filesList = this.attachmentsFiles
      if (filesList !== null && filesList.length > 0) {
        filesList.forEach(file => {
          formData.append(file.name, file)
        })
      }
      return new Promise((resolve, reject) => {
        this.$api.execute('post', 'mailattachments/upload', formData)
          .then(({ data }) => {
            if (data && data.length) {
              const attachmentIds = data.map(x => x._id)
              resolve(attachmentIds)
            }
          })
      })
    },
    async onFilePicked (fileslist, index) {
      const item = this.$formatter.cloneVariable(this.replyForwardItem)
      const files = fileslist
      if (item.attachmentsFiles) {
        item.attachmentsFiles = this.replyForwardItem.attachmentsFiles
      }
      for (let i = 0; i < files.length; i++) {
        if (files[i]) {
          // if (!item.attachmentsList) item.attachmentsList = []
          // item.attachmentsList.push(files[i].name)
          // if (!item.attachmentsFiles) item.attachmentsFiles = []
          // item.attachmentsFiles.push(files[i])
          // if (this.imagesTypes.includes(files[i].type)) {
          //   const base64 = await this.toBase64(files[i])
          //   if (!item.listOfFiles) item.listOfFiles = []
          //   item.listOfFiles.push(base64)
          //   this.$set(this.ticketMails, index, item)
          // } else {
          // }
          if (!item.attachmentsList) item.attachmentsList = []
          item.attachmentsList.push(files[i].name)
          if (!item.attachmentsFiles) item.attachmentsFiles = []
          item.attachmentsFiles.push(files[i])
          if (!item.listOfOtherFiles) item.listOfOtherFiles = []
          const iconObj = this.getMimeTypeIcons(files[i].type)
          item.listOfOtherFiles.push({ name: files[i].name, type: files[i].type, ...iconObj })
          // this.replyForwardItem = item
          this.$set(this.ticketMails, index, item)
        }
      }
    },
    removeAttachment (fileIndex, listname, mainIndex) {
      // const item = this.$formatter.cloneVariable(this.ticketMails[mainIndex])
      this.replyForwardItem[listname].splice(fileIndex, 1)
      // this.$set(this.ticketMails, mainIndex, item)
    },
    pickFile () {
      // const name = `attachments_${index}`
      this.typeOfFileInput = 1
      this.$refs.file.click()
    },
    sendAssignedNotification (number, groupID, ticketid) {
      const group = this.getListOfGroups.find(x => x._id === groupID)
      return `${group.name} is assigned for ${number} by ${this.userDetails.name}`
    },
    deleteConfirmed () {
      const modelObj = { data: { ids: [this.modelObj._id], delete_mails: this.isDeleteMails } }
      this.$api.execute('delete', `${this.MODULE_URL}/delete_multiple`, modelObj)
        .then(response => {
          if (response && response.data) {
            const text = 'deleteSuccess'
            this.$root.$emit('snackbar', { snackbar: true, color: 'success', text })
            this.$router.push(`/${this.MODULE_URL}`)
          }
        })
        .finally(() => {
          this.showDeleteDialog = false
        })
    },
    deleteHandler () {
      this.showDeleteDialog = true
    },
    convertHtml (htmlString) {
      return htmlString
    },
    focusMessageArea (index) {
      setTimeout(() => {
        var div = document.getElementsByClassName('ck-editor__editable_inline')
        setTimeout(() => {
          if (div[index]) div[index].focus()
        }, 50)
      }, 100)
      let item = ''
      const currentUser = this.getUsers.find(x => x._id === this.userDetails._id)
      if (currentUser && currentUser.signature) {
        item = `${currentUser.signature}`
        document.getElementById('userSignFooter').srcdoc = item
        setTimeout(() => {
          const element = document.getElementById('userSignFooter')
          element.style.height = (element.contentWindow.document.all[2].offsetHeight + 30) + 'px'
          element.style.pointerEvents = 'none'
          // const iWindow = iframe.contentWindow;
          // const iDocument = iWindow.document;
          // const tag = element.getElementsByClassName('user-sign')
          element.style.color = this.$vuetify.theme.dark ? 'white' : ''
        }, 300)
      }
    },
    // replyMail (item, index) {
    //   const mailAddress = this.ticketMails[index].mailfrom && this.ticketMails[index].mailfrom.length > 0 ? item.mailfrom.map(function (elem) { return elem.Address }) : []
    //   const itemObj = Object.assign({ ...item }, {
    //     isShowMailForm: true,
    //     mailTo: mailAddress,
    //     mailBody: !this.modelObj.is_task ? this.bodySignature : '',
    //     showMailCc: false,
    //     showMailBcc: false,
    //     mailCc: [],
    //     mailBcc: []
    //   })
    //   this.$set(this.ticketMails, index, itemObj)
    //   this.actionType = 1
    //   this.showReplyForwardForm(this.ticketMails[index])
    // },
    replyMail (item, index) {
      this.panel = [index]
      let combinedArray = [this.ticketMails[index].from.emailaddress.address].concat(this.ticketMails[0].torecipents.map(recipient => recipient.emailaddress.address))
      combinedArray = combinedArray.filter(email => email !== this.ticketMails[index].mailboxname)
      combinedArray = [...new Set(combinedArray)]
      const mailAddress = this.ticketMails[index].from ? combinedArray : []
      const itemObj = Object.assign({ ...item }, { isShowMailForm: true, mailTo: mailAddress, mailBody: !this.modelObj.is_task ? this.bodySignature : '', showMailCc: false, showMailBcc: false, mailCc: [], mailBcc: [], index: index })
      this.isForwardMail = false
      this.replyForwardItem = itemObj
      this.replyOrForwardDialog = true
      // this.$set(this.ticketMails, index, itemObj)
      setTimeout(() => {
        this.focusMessageArea(index)
      })
    },
    replyAllMail (item, index) {
      this.isForwardMail = false
      this.$set(item, 'mailTo', item.torecipents.map(recipient => recipient.emailaddress.address))
      const combinedArray = [this.ticketMails[index].from.emailaddress.address].concat(this.ticketMails[index].torecipents.map(recipient => recipient.emailaddress.address))
      let mailAddress = this.ticketMails[index].from ? combinedArray : []
      const from = this.ticketMails[index].from && this.ticketMails[index].from.length > 0 ? item.from.map((elem) => { return elem.Address }) : []
      if (from) {
        mailAddress = [...mailAddress, ...from]
      }
      const mailCc = this.ticketMails[index].ccrecipents.length > 0 ? this.ticketMails[index].ccrecipents.map((elem) => { return elem.emailaddress.address }) : []
      const mailBcc = this.ticketMails[index].bccrecipents.length > 0 ? this.ticketMails[index].bccrecipents.map((elem) => { return elem.emailaddress.address }) : []
      if (this.modelObj.mailbox && this.modelObj.mailbox.length > 0) mailAddress = mailAddress.filter(x => x !== this.modelObj.mailbox[0].username)
      const itemObj = Object.assign({ ...item },
        { isShowMailForm: true, mailTo: mailAddress, mailCc, mailBcc, mailBody: !this.modelObj.is_task ? this.bodySignature : '', showMailCc: mailCc.length > 0, showMailBcc: mailBcc.length > 0 })
      this.isForwardMail = false
      this.replyForwardItem = itemObj
      this.replyOrForwardDialog = true
      // this.$set(this.ticketMails, index, itemObj)
      setTimeout(() => {
        this.focusMessageArea(index)
      })
    },
    forwardMail (item, index) {
      // let mailAddress this.ticketMails[index].mailfrom && this.ticketMails[index].mailfrom.length > 0 ? item.mailfrom.map(function(elem){return elem.Address}) : []
      const itemObj = Object.assign({ ...item }, { isShowMailForm: true, mailTo: [], mailBody: !this.modelObj.is_task ? this.bodySignature : '' })
      // this.$set(this.ticketMails, index, itemObj)
      this.replyForwardItem = itemObj
      this.replyOrForwardDialog = true
      this.isForwardMail = true
      setTimeout(() => {
        this.focusMessageArea(index)
      })
    },
    showReplyForwardForm (item) {
      if (item) {
        this.selectedMailItem = this.$formatter.cloneVariable(item)
        this.showReplyForwardMenu = true
      }
      this.showReplyForwardMenuWidth = +(document.getElementById('container-div').offsetWidth)
      setTimeout(() => {
        let signature = ''
        const currentUser = this.getUsers.find(x => x._id === this.userDetails._id)
        if (currentUser && currentUser.signature) {
          signature = `${currentUser.signature}`
          document.getElementById('userSign').srcdoc = signature
          setTimeout(() => {
            const element = document.getElementById('userSign')
            element.style.height = (element.contentWindow.document.all[2].offsetHeight + 30) + 'px'
            element.style.pointerEvents = 'none'
          }, 300)
        }
      }, 500)
    },
    showHideInput (item, index, field) {
      if (field === 'cc') {
        item.showMailCc = !item.showMailCc
      } else item.showMailBcc = !item.showMailBcc
      this.$set(this.ticketMails, index, item)
    },
    sendMailOrAttachment (item, index) {
      if (this.$refs[`mailForm_${index}`][0].validate()) {
        if ((item.listOfFiles && item.listOfFiles.length > 0) || (item.listOfOtherFiles && item.listOfOtherFiles.length > 0)) {
          var formData = new FormData()
          var filesList = item.attachmentsFiles
          if (filesList !== null && filesList.length > 0) {
            filesList.forEach(file => {
              formData.append(file.name, file)
            })
          }
          this.sendMailLoading = true
          this.$api.execute('post', 'mailattachments/upload', formData)
            .then(response => {
              if (response.data && response.data.length > 0) {
                const attachmentIds = response.data.map(x => x._id)
                item.attachments = attachmentIds
                this.$set(this.ticketMails, index, item)
                this.sendMail(item, index)
              }
            })
        } else this.sendMail(item, index)
      }
    },
    sendMail (item, index) { // item, index
      const mailItem = this.$formatter.cloneVariable(item)
      if (this.$refs.mailForm.validate()) {
        let body = this.$formatter.isArrayHasData(mailItem.mailBody) ? mailItem.mailBody : ''
        const to = this.$formatter.isArrayHasData(mailItem.mailTo) ? mailItem.mailTo : []
        const formattedto = to.map(email => ({
          emailaddress: {
            address: email,
            name: ''
          }
        }))
        const cc = this.$formatter.isArrayHasData(mailItem.mailCc) ? mailItem.mailCc : []
        const formattedcc = cc.map(email => ({
          emailaddress: {
            address: email,
            name: ''
          }
        }))
        const bcc = this.$formatter.isArrayHasData(mailItem.mailBcc) ? mailItem.mailBcc : []
        const formattedbcc = bcc.map(email => ({
          emailaddress: {
            address: email,
            name: ''
          }
        }))
        const arrayOfEmails = [to, cc, bcc]
        if (arrayOfEmails.length > 0) {
          for (let i = 0; i < arrayOfEmails.length; i++) {
            for (let j = 0; j < arrayOfEmails[i].length; j++) {
              if (!this.emailRegex.test(arrayOfEmails[i][j])) {
                this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'invalidEmail' })
                return false
              }
            }
          }
        }
        this.sendMailLoading = true
        body = body ? body.replace('<iframe width="100%" frameborder="0" id="userSignFooter"> </iframe>', '') : ''
        const sendObj = {
          messageId: mailItem.mailid,
          mailboxId: this.$route.query.mailboxid,
          // from: this.ticketMails[index].from,
          comment: body,
          toRecipients: formattedto,
          ccRecipients: formattedcc,
          bccRecipients: formattedbcc
          // attachments: addedAttachments && item.attachments && item.attachments.length > 0 ? item.attachments : [],
          // ticket_id: [this.modelObj._id]
        }
        // return false
        var formData = new FormData()
        if (item.attachmentsFiles && item.attachmentsFiles.length) {
          var filesList = item.attachmentsFiles
          if (filesList !== null && filesList.length) {
            filesList.forEach(file => {
              if (!file.is_sharepoint) {
                formData.append(file.name, file)
              } else {
                formData.append(file.id, file)
              }
            })
          }
        }
        formData.append('model', JSON.stringify(sendObj))
        let url = '/mails/replymail_through_graphapi'
        if (this.isForwardMail) url = '/mails/forwardmail_through_graphapi'
        this.$api.execute('post', url, formData)
          .then((response) => {
            item.listOfOtherFiles = []
            item.listOfFiles = []
            item.attachmentsFiles = []
            if (response.status === 200) {
              this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'sentSuccess' })
              item.isShowMailForm = false
              // this.$set(this.ticketMails, index, item)
              this.getSingleRecordHandler(true)
            }
          })
          .finally(() => {
            this.sendMailLoading = false
            this.replyOrForwardDialog = false
          })
      }
    },
    cancelMail (item, index) {
      item.isShowMailForm = false
      this.$set(this.ticketMails, index, item)
      this.showReplyForwardMenu = false
    },
    mimeTypeOfDocument (type) {
      let icon = ''
      switch (type) {
        case '.png':
        case '.jpeg':
        case '.gif':
        case '.jpg':
        case '.jfif':
          icon = { icon: 'mdi-file-image', color: '' }
          break
        case '.pdf':
          icon = { icon: 'mdi-file-pdf', color: 'red' }
          break
        case '.xlsx':
          icon = { icon: 'mdi-file-excel', color: 'green' }
          break
        case '.html':
          icon = { icon: 'mdi-language-html5', color: '' }
          break
        case '.mp4':
          icon = { icon: 'mdi-file-video', color: '' }
          break
        case '.mpeg':
          icon = 'mdi-audiobook'
          break
        case 'application/msword':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.template':
          icon = { icon: 'mdi-file-word', color: 'info' }
          break
        case 'application/vnd.ms-excel':
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.template':
          icon = { icon: 'mdi-file-excel', color: 'green' }
          break
        case 'application/vnd.ms-powerpoint':
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        case 'application/vnd.openxmlformats-officedocument.presentationml.template':
        case 'application/vnd.openxmlformats-officedocument.presentationml.slideshow':
          icon = { icon: 'mdi-file-powerpoint', color: 'error' }
          break
        case 'application/x-rar-compressed':
        case 'application/octet-stream':
        case 'application/zip':
        case 'application/x-zip-compressed':
        case 'multipart/x-zip':
          icon = { icon: 'mdi-zip-box', color: null }
          break
        default:
          icon = { icon: 'mdi-file-cloud', color: null }
          break
      }
      return icon
    },
    downloadFile (attachmentid, mailid) {
      // this.$api.execute('get', `/mailattachments/download/${id}`).then(response => {
      //   const filename = response.data
      //   window.open(`${process.env.VUE_APP_URL}files/download?fileName=${filename}`, '_blank')
      // })
      const mailboxid = this.$route.query.mailboxid
      this.$api.execute('post', `mails/download_attachments/${mailboxid}/${mailid}/${attachmentid}`).then(response => {
        const filename = response.data.filename
        window.open(process.env.VUE_APP_URL + 'files/download/?fileName=' + filename, '_blank')
      })
    },
    convertTypeHandler () {
      let queryname = ''
      const taskOrTicketInfo = this.$formatter.cloneVariable(this.modelObj)
      // if (taskOrTicketInfo) {
      //   let filteredMail = this.$formatter.cloneVariable(this.ticketMails.find(x => x.mailid === taskOrTicketInfo.mailid))
      //   taskOrTicketInfo.mailbody = filteredMail ? filteredMail.body : ''
      // }
      if (taskOrTicketInfo.is_task) queryname = 'convertticket'
      else {
        queryname = 'converttask'
        taskOrTicketInfo.mailbody = this.ticketMails[0].body
      }
      window.localStorage.setItem('taskOrTicketInfo', JSON.stringify(taskOrTicketInfo))
      this.$router.push({ path: `/tickets/create?${queryname}=true&mailboxid=${this.$route.query.mailboxid}&conversationid=${this.$route.query.conversationid}` })
    },
    convertToSale () {
      const obj = this.$formatter.cloneVariable(this.modelObj)
      const contactArray = []
      let contact = ''
      let assignedTo = this.userDetails._id
      const saleModule = this.modulesRelated.find(x => x.name === this.SALE)
      if (obj.assignedto) {
        const splitValue = obj.assignedto.split('_')
        if (splitValue[0] !== 'group') assignedTo = splitValue[1]
      }
      const hasSelectedcontact = this.modulesRelated.find(x => x.name === 'Contact')
      if (hasSelectedcontact && hasSelectedcontact.selectedValue) {
        const foundArray = hasSelectedcontact.listOfValues.find(x => x._id === hasSelectedcontact.selectedValue)
        if (foundArray) {
          contactArray.push({
            _id: foundArray._id,
            name: foundArray.data.name
          })
          contact = hasSelectedcontact.selectedValue
        }
      }
      let companyArray = []
      if (obj.account_info && obj.account_info.length > 0) {
        companyArray = [{
          _id: obj.account_info[0]._id,
          name: obj.account_info[0].data.name
        }]
      }
      const model = {
        resposible: assignedTo,
        company: obj.account_id,
        contact,
        name: this.ticketMails[0].subject,
        companyArray,
        contactArray
      }
      if (saleModule) {
        this.$router.push(`/module/${saleModule.name}/${saleModule._id}/actions?fromticket=${encodeURIComponent(JSON.stringify(model))}`)
      }
    },
    showMailInfoEdit (item, index) {
      this.updateMailInfo = this.$formatter.cloneVariable(item)
      this.showFieldsForTask = true
    },
    updateMailValues (modelObj) {
      const model = this.$formatter.cloneVariable(this.updateMailInfo)
      this.$api.execute('post', `tickets/update_task/${this.modelObj._id}`, { body: model, subject: modelObj.subject }).then(response => {
        this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'updatedSuccess' })
        this.showFieldsForTask = false
        this.getSingleRecordHandler()
      })
    },
    // loadValues () {
    //   if (this.modelObj.account_id) {
    //     this.modulesRelated.forEach((element, index) => {
    //       this.modulesRelated[index].listOfValues = []
    //       element.selectedValue = ''
    //       this.getRelatedList(element.name, index)
    //     })
    //   }
    // },
    loadValues () {
      this.modulesRelated.forEach((element, index) => {
        element.selectedValue = ''
        this.modulesRelated[index].listOfValues = []
        if (element.name === this.PROJECT) this.getProjectById(this.modelObj)
        else if (this.modelObj.account_id && this.getAccountSubModules.includes(element.name)) {
          element.selectedValue = ''
          this.getRelatedList(element.name, index)
        }
      })
    },
    async getRelatedList (name, index, searchTerm = '') {
      if (name !== this.PROJECT) {
        let url = `moduledata/${name}/live_search_for_dropdown?searchterm=${searchTerm || ''}`
        if (this.getAccountSubModules.includes(name)) url += `&relation_module=${this.ACCOUNT}&relation_id=${this.modelObj.account_id}`
        await this.$api.execute('get', url).then(({ data: result }) => {
          result.forEach(item => {
            item.data.name = `${item.data.name || ''} ${item.data.last_name || ''}`
          })
          this.$set(this.modulesRelated, index, { ...this.modulesRelated[index], listOfValues: result })
        })
      }
    },
    getInitials (name) {
      if (name && name.indexOf(' ') !== -1) {
        const [firstName, lastName] = name.split(' ')
        return `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`
      } else {
        return name.substring(0, 2).toUpperCase()
      }
    },
    formatedDate (date) {
      // Assuming formatDate is a method in your component
      return moment(date, 'DD-MM-YYYY').format('dddd').slice(0, 3)
    },
    toggleExpansionPanel (index) {
      if (this.ticketMails[index].hasattachments) {
        this.getAttachmentById(index)
      }
    },
    async getAttachmentById (index) {
      let payload = {}
      if (this.ticketMails.length !== 0) {
        payload = {
          mailboxid: this.$route.query.mailboxid,
          messageid: this.ticketMails[index].mailid
        }
        if (payload.mailboxid != null) {
          this.$api.execute('post', 'mails/get_attachments_by_id', payload).then(({ data }) => {
            this.$set(this.ticketMails[index], 'body', this.convertHtml(data.body || ''))
            const attachments = data.attachments.map(attachment => {
              const getExtensionType = this.mimeTypeOfDocument(attachment.extension)
              return { ...attachment, icon: getExtensionType.icon, color: getExtensionType.color }
            })
            this.$set(this.ticketMails[index], 'otherAttachments', attachments)
            setTimeout(() => {
              this.setTheAttachmentNameMaxWidth()
            })
          })
        }
      }
    },
    setTheAttachmentNameMaxWidth () {
      var cards = document.getElementsByClassName('attachment_card')
      if (cards && cards.length) {
        var attachmentCard = cards[0]
        this.attachmentFileNameMaxWidth = attachmentCard.clientWidth - 125
      }
    },
    getEmailSuggestions (item, index, model) {
      const searchTerm = item[`${model}FieldSearchText`] || ''
      item[`${model}EmailSuggestionloading`] = true
      this.$set(this.ticketMails, index, item)
      this.$api.execute('get', `emailaccounts/get_email_suggesstion?searchTerm=${searchTerm || ''}`).then(({ data: emailSuggestions }) => {
        const suggestedEmails = emailSuggestions.map(x => {
          x.nameAndEmail = [x.name, (x.name ? `(${x.emailaddress})` : x.emailaddress)].filter(x => x).join(' ')
          return x
        })
        this.listOfEmailSuggestions = []
        this.listOfEmailSuggestions = suggestedEmails
      }).finally(() => {
        item[`${model}EmailSuggestionloading`] = false
        this.$set(this.ticketMails, index, item)
      })
    },
    onChange () {
      this.filelist = [...this.$refs.file.files]
      this.includeFiles()
    },
    remove (i) {
      this.filelist.splice(i, 1)
    },
    openDocument (url) {
      window.open(url, '_blank')
    },
    openAttachmentsDialog (index, type) {
      this.currentItem = index
      this.typeOfFileInput = type
      this.attachmentsDialog = true
    },
    includeFiles () {
      if (this.typeOfFileInput === 1) {
        this.onFilePicked(this.filelist, this.currentItem)
        // this.attachmentsDialog = false
        this.closeAttachmentsDialog()
      } else this.$eventBus.$emit('copySelectedDocuments')
    },
    getAttachmentFiles (files) {
      this.onFilePicked(files, this.currentItem)
      this.closeAttachmentsDialog()
      this.currentItem = null
    },
    closeAttachmentsDialog () {
      this.filelist = []
      this.attachmentsDialog = false
    }
  },
  beforeDestroy () {
    this.$root.$off('loadComments')
    this.$eventBus.$off('deleteItems')
    this.$eventBus.$off('deleteSuccess')
  }
}
</script>
<style>
.custom-ckeditor-height .ck-content {
  min-height: 75px;
}
.custom_toolbar_content > .v-toolbar__content {
  padding: 0px !important;
}
.ck-focused {
  border: 1px solid #c4c4c4 !important;
}

.v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 48px !important;
}
.notes-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 160px;
  height: 1.2em;
  white-space: nowrap;
  position: fixed;
}
.notes-wrapper {
    min-width: 80px !important;
    background-color: rgba(0, 0, 0, 0.3) !important;
    border-color: rgba(0, 0, 0, 0.3) !important;
    position: fixed !important;
    top: 11.3vh !important;
    right: -25px !important;
    border-radius: 8px !important;
}
.notes-wrapper--xs {
     top: 14.5vh !important;
}
.notes-wrapper--sm {
     top: 10.5vh !important;
}
.image img {
  height: 400px;
}
.v-menu__content.theme--light.menuable__content__active.custom_position_style {
    position: fixed !important;
    top: 1rem !important;
}
.noscroll{
  overflow: hidden;
}
.fullscreen{
  overflow-x: hidden !important;
}
.v-slide-group__prev {
  display: contents !important
}
[v-cloak] {
  display: none;
}
.p-12 {
  padding: 1.5rem;
}
.border {
  border-width: 1px;
}
.border-gray-300 {
  border-color: #e2e8f0;
}
.bg-gray-100 {
  background-color: #e1e1e1;
  border: 2px dashed #036693b5;
}
.w-px {
  width: 1px;
}
.absolute {
  position: absolute;
}
.overflow-hidden {
  overflow: hidden;
}
.opacity-0 {
  opacity: 0;
}
.h-px {
  height: 1px;
}
.block {
  display: block;
}
.underline {
  text-decoration: underline;
}
.text-sm {
  font-size: .875rem;
}
.p-1 {
  padding: 0.25rem;
}
.width-100 {
  width: 100%;
  text-align: center;
}
.loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
}
.loading-text {
    color: rgb(124, 130, 130);
}
.loader {
  text-align: center;
  margin:5px 0 -1px 0;
}
.custom_rounded-xxl {
  border-radius: 8px;
}
.show_divider_border_left {
  border-left: 1px solid rgba(0, 0, 0, 0.085);
}
.center_aligned_file_name {
  display: flex;
  align-items: center;
}
.inline_block{
  display: inline-block;
}
.attachment_name_max_width {
  max-width: var(--attachment_name_max_width);
}
.show_divider_border_right {
  border-right: 1px solid rgba(0, 0, 0, 0.085);
}
/* .notes .v-menu__content{
  max-width: 100%;
} */
</style>
